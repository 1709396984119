import { Container, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";

import "./App.css";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zorkonline.net</title>
      </Helmet>
      <Container fluid>
        <Image
          src="/Zork_I_box_art.jpg"
          className="position-absolute top-50 start-50 translate-middle"
        />
      </Container>
    </>
  );
}

export default App;
